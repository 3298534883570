import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/presse-helles-alkoholfrei-preview.jpg'
import presseImage1 from '@images/presse/Festl_Tour_Impressionen_6.jpg'
import presseImage2 from '@images/presse/Festl_Tour_Impressionen_7.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Ab auf den Genussgipfel: ERDINGER Brauhaus Helles Alkoholfrei ist ab sofort verfügbar
              </Subtitle>
              <ContentText>
                <p>
                  Erding, 30.01.2025<br />
                  Das Warten hat für Händler, Wirte und Hellbierliebhaber endlich ein Ende! Ab 
                  sofort gibt es ERDINGER Brauhaus Helles Alkoholfrei im Handel und in der Gastronomie. Süffig, sorglos, 
                  bayerisch führt die neue untergärige Spezialität aus dem Hause ERDINGER auf den höchsten 
                  Genussgipfel. ERDINGER Brauhaus Helles Alkoholfrei ist einzeln in der 0,5l-Flasche, im 20x-0,5l-Kasten 
                  sowie im 6x-0,5l-Open-Basket erhältlich.
                </p>
                <p>
                  „Die Marke ERDINGER Brauhaus hat sich als wichtige Größe im Biermarkt etabliert. Jetzt ist der 
                  perfekte Zeitpunkt für den nächsten Schritt. Mit ERDINGER Brauhaus Helles Alkoholfrei vereinen wir 
                  die zwei erfolgreichsten Markttrends: alkoholfrei und Hellbier. Unser wichtigstes Erfolgsrezept ist auch 
                  dieses Mal beste bayerische Braumeisterqualität und der Geschmack. Unseren Braumeistern ist ein 
                  großartiges, alkoholfreies Helles gelungen, das eine frische Süffigkeit, harmonische Hopfennote und 
                  der volle Biergeschmack auszeichnet“, so Dr. Stefan Kreisz, Vorsitzender der Geschäftsführung der 
                  Privatbrauerei ERDINGER Weißbräu.
                </p>
                <p>
                  ERDINGER Brauhaus Helles Alkoholfrei schmeckt einzigartig mild und vollmundig. Bereits sein kräftiges 
                  Gold-Gelb und seine weiße, feinporige Schaumkrone machen Lust auf den ersten Schluck. Und der 
                  lohnt sich! ERDINGER Brauhaus Helles Alkoholfrei begeistert mit vollem Biergeschmack. Erfrischend 
                  süffig, spritzig leicht entfaltet sich der ausgewogene Malzcharakter. Dazu gesellt sich eine angenehme, 
                  blumige Hopfennote. Die stimmige Balance aus der natürlichen Süße des Malzes und der Bittere des 
                  aromatischen Hopfens machen das alkoholfreie Helle zum wahren Trinkgenuss, der lange anhält und 
                  zum Weitertrinken anregt. Dezente Waldhonig-Aromen runden das Profil herrlich ab. ERDINGER 
                  Brauhaus Helles Alkoholfrei wird ausschließlich mit sorgfältig ausgewählten Zutaten und strikt nach 
                  dem Bayerischen Reinheitsgebot von 1516 gebraut. Am besten bei 7 Grad genießen und dank bester 
                  bayerischer Braumeister-Qualität ohne Kompromisse immer einen klaren Kopf bewahren.
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <img src={pressePreviewImage} alt="Ab auf den Genussgipfel: ERDINGER Brauhaus Helles Alkoholfrei ist ab sofort verfügbar" />
                </div>
              </ContentImageWrapper>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
